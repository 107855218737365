<template>
  <div>
    <div v-for="(info, idx) in contestInfoList" :key="idx">
      <div class="download_container">
        <!-- 下载报名表 布局 -->
        <div>
          <el-row>
            <el-col :span="15">
              <div class="name">
                {{ info.contestName }}
              </div>
              <div class="register-time">
                报名截止时间：{{ info.registrationEndTime }}
              </div>
            </el-col>
            <el-col :span="9">
              <el-button
                class="btn-download"
                @click="enrollContest(info.id)"
              >报名参赛
              </el-button>
              <!-- 预览 按钮 -->
              <el-button
                class="btn-preview"
                @click="enrollContest(info.id)"
              >
                <svg-icon name="signup" color="#ffffff" width="18" />
              </el-button>
            </el-col>
          </el-row>
        </div>

        <!-- 右上角图标：有间隙 -->
        <div class="mask" />
        <div class="mask_content">
          进行中
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import '@/icons/signup'
import { retrieveUserEnrollInfo } from '@/api/contest'

export default {
  name: 'SelectContest',
  props: {
    contestInfoList: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      contestInfo: [{
        id: 2,
        contestName: '2021年北京市大学生工业设计大赛',
        registrationEndTime: '2021-08-21'
      }, {
        id: 12,
        contestName: '2021年北京市大学生工业设计大赛',
        registrationEndTime: '2021-08-21'
      }]
    }
  },
  methods: {
    // 报名参赛
    enrollContest(contestId) {
      retrieveUserEnrollInfo(this.globalGetUsername(), contestId).then(Response => {
        if (Response.data !== null && Response.data.length >= 3) {
          this.$message.error('报名达到上限!')
        } else {
          this.$router.push({ name: 'EnrollStart', params: { contestId: contestId }})
        }
      })
    }
  }
}
</script>

<style scoped>
@import '~@/styles/theme.css';

/* 小框框 */
/* 下载报名表 容器 */
.download_container {
  width: 100%;
  height: 100px;
  display: inline-block;
  margin: auto;
  margin-top: 20px;
  border: 1px solid #595159;
  border-radius: 5px;
  padding-top: 30px;
  padding-bottom: 30px;
  /*  用于 标签 定位 */
  position: relative;
  overflow: hidden;
}

.name {
  padding-left: 40px;
  font-size: 16px;
  font-weight: 800;
}

.register-time {
  padding-left: 40px;
  font-size: 10px;
}

/* 下载 图标 */
.svg-icon-upload {
  vertical-align: bottom;
}

/* 上传报名表 按钮 */
.btn-upload {
  display: inline-block;
  /* 定位相关 */
  vertical-align: bottom;
  height: 40px;
  border-radius: 8px 0 0 8px;
  margin-right: -10px;
  margin-left: 10px;
  border: none;
  /* 样式相关 */
  background: #6a5cb3;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  padding: 5px 20px;
}

/* 预览按钮 */
.btn-midline {
  display: inline-block;
  /* 设置div里面的文字无法被选中 */
  user-select: none;
  /* 定位相关 */
  vertical-align: bottom;
  height: 40px;
  border: none;
  border-radius: 0 8px 8px 0;
  /* 样式相关 */
  background: #5fdfc6;
  color: #fff;
  font-size: 14px;
  padding: 15px 15px;
}

/* 提示文字1 */
.tip_download1 {
  color: #7c7c7c;
  margin-top: 15px;
  cursor: pointer;
}

/* 提示文字2 */
.tip_download2 {
  color: #7c7c7c;
  font-size: 6px;
  margin-top: 5px;
}

/* 右上角有间隙 样式 */
.mask {
  position: absolute;
  top: 11px;
  right: -4px;
  width: 32px;
  height: 5px;
  transform: rotate(45deg);
  border-width: 0px 2px 2px 2px;
  border-style: solid;
  border-color: transparent transparent var(--theme-color) transparent;
}

.mask_content {
  position: absolute;
  top: 16px;
  right: -16px;
  width: 80px;
  height: 20px;
  transform: rotate(45deg);
  border-width: 0px 20px 20px 20px;
  border-style: solid;
  border-color: transparent transparent var(--theme-color) transparent;
  color: white;
  font-size: 13px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
}

/* 上传报名表 容器 */
.upload_container {
  border: 1px solid #817481;
  border-radius: 5px;
  padding-top: 70px;
  padding-bottom: 30px;
  /*  用于 标签 定位 */
  position: relative;
  overflow: hidden;
}

/* 下载 图标 */
.svg-icon-download {
  transform: rotate(180deg);
  vertical-align: top;
}

/* 下载报名表 按钮 */
.btn-download {
  /* 定位相关 */
  vertical-align: top;
  height: 35px;
  width: 125px;
  border-radius: 8px 0 0 8px;
  margin-right: -10px;
  margin-left: 10px;
  border: none;
  /* 样式相关 */
  background: var(--background-color);
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  padding: 5px 20px;
}

/* 预览按钮 */
.btn-preview {
  /* 定位相关 */
  vertical-align: top;
  height: 35px;
  width: 35px;
  border: none;
  border-radius: 0 8px 8px 0;
  /* 样式相关 */
  background: var(--theme-color);
  color: #fff;
  font-size: 14px;
  padding: 5px 5px;
}

/* 提示文字1 */
.tip_upload1 {
  color: #7c7c7c;
  margin-top: 15px;
  cursor: pointer;
}

/* 提示文字2 */
.tip_upload2 {
  color: #7c7c7c;
  font-size: 6px;
  margin-top: 5px;
}

</style>
